<template>
    <div class="image-editor">
      <div class="editor-layout">
        <!-- Contenedor de la imagen -->
        <div class="image-container" ref="containerRef">
          <v-stage ref="stageRef" :config="stageSize">
            <v-layer>
                <v-image 
                    v-if="mainImage.value" 
                    :config="{ image: mainImage.value, width: stageSize.width, height: stageSize.height, listening: false }" 
                    />

              <v-image
                v-if="watermarkProps.image"
                :config="{
                  image: watermarkProps.image,
                  x: watermarkProps.x,
                  y: watermarkProps.y,
                  draggable: true,
                  width: watermarkProps.width,
                  height: watermarkProps.height,
                  opacity: watermarkProps.opacity
                }"
                @dragmove="handleDrag"
              />
            </v-layer>
          </v-stage>
        </div>
        <!-- Controles para ajustar la imagen -->
        <div class="control-panel">
            <label :class="['custom-file-upload', { loaded: isMainImageLoaded }]">
                <input type="file" @change="loadMainImage" accept="image/*" />
                Cargar Imagen de Fondo
                </label>

          <label :class="['custom-file-upload', { loaded: !!watermarkProps.image }]">
            <input type="file" @change="loadWatermark" accept="image/*" />
            Cargar Marca de Agua
          </label>
          <label for="opacityRange">Ajustar Opacidad:</label>
          <input type="range" id="opacityRange" min="0" max="1" step="0.01" v-model="watermarkSettings.opacity" @input="updateOpacity" />
          <label for="sizeRange">Ajustar Tamaño de la Marca de Agua:</label>
          <input type="range" id="sizeRange" min="0" max="1" step="0.01" v-model="watermarkSettings.size" @input="updateSize" />
          <button class="custom-button" @click="downloadImage">Descargar Imagen</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, reactive, watch, onMounted, onBeforeUnmount } from 'vue';
  import { VStage, VLayer, VImage } from 'vue-konva';
  
  export default {
    components: {
      VStage,
      VLayer,
      VImage,
    },
    setup() {
        const mainImage = new Image();
      const isMainImageLoaded = ref(false);
      const stageSize = ref({ width: 800, height: 600 });
      const stageRef = ref(null);
      const containerRef = ref(null);
      const watermarkSettings = reactive({
        opacity: 0.5,
        size: 0.5,
      });
      const watermarkProps = reactive({
        x: 0,
        y: 0,
        opacity: 0.5,
        width: 200,
        height: 200,
        image: null,
      });
  
      function loadMainImage(event) {
        const file = event.target.files[0];
        if (!file) return;  // Verifica si el archivo no es nulo
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image(); // Crea una nueva instancia de Image
            img.src = e.target.result;
            img.onload = () => {
            mainImage.value = img; // Asigna la imagen cargada a mainImage.value
            adjustStageSize();
            isMainImageLoaded.value = true; // Actualiza el estado de carga
            console.log('Imagen cargada:', mainImage.value); // Verifica la imagen cargada
            };
        };
        reader.readAsDataURL(file);
        }

  
      const adjustStageSize = () => {
        if (containerRef.value) {
          const maxWidth = containerRef.value.clientWidth * 0.95;
          const maxHeight = containerRef.value.clientHeight * 0.95;
          const aspectRatio = mainImage.value.width / mainImage.value.height;
  
          let width = maxWidth;
          let height = width / aspectRatio;
  
          if (height > maxHeight) {
            height = maxHeight;
            width = height * aspectRatio;
          }
  
          stageSize.value = { width, height };
        }
      };
  
      function loadWatermark(event) {
        const file = event.target.files[0];
        if (!file) return;  // Verifica si el archivo no es nulo
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;        
          img.onload = () => {
            // mainImage.value = img;
            // mainImage.value = img; // Asignar la imagen una vez cargada
            // adjustStageSize();
            watermarkProps.image = img;
            const aspectRatio = img.width / img.height;
            let maxWidth = Math.min(stageSize.value.width * 0.5, img.width);
            let maxHeight = maxWidth / aspectRatio;
            if (maxHeight > stageSize.value.height * 0.5) {
              maxHeight = stageSize.value.height * 0.5;
              maxWidth = maxHeight * aspectRatio;
            }
            watermarkProps.width = maxWidth;
            watermarkProps.height = maxHeight;
            watermarkProps.x = (stageSize.value.width - watermarkProps.width) / 2;
            watermarkProps.y = (stageSize.value.height - watermarkProps.height) / 2;
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
  
      function updateOpacity() {
        watermarkProps.opacity = watermarkSettings.opacity;
      }
  
      function updateSize() {
        if (!watermarkProps.image) return;
  
        const aspectRatio = watermarkProps.image.width / watermarkProps.image.height;
        let newWidth = stageSize.value.width * watermarkSettings.size;
        let newHeight = newWidth / aspectRatio;
  
        if (newHeight > stageSize.value.height * watermarkSettings.size) {
          newHeight = stageSize.value.height * watermarkSettings.size;
          newWidth = newHeight * aspectRatio;
        }
  
        newWidth = Math.max(1, newWidth);
        newHeight = Math.max(1, newHeight);
  
        let newX = Math.max(0, Math.min(watermarkProps.x, stageSize.value.width - newWidth));
        let newY = Math.max(0, Math.min(watermarkProps.y, stageSize.value.height - newHeight));
  
        watermarkProps.width = newWidth;
        watermarkProps.height = newHeight;
        watermarkProps.x = newX;
        watermarkProps.y = newY;
      }
  
      function handleDrag(e) {
        const node = e.target;
        const box = node.getClientRect();
        let newX = Math.max(0, Math.min(stageSize.value.width - box.width, node.x()));
        let newY = Math.max(0, Math.min(stageSize.value.height - box.height, node.y()));
        node.x(newX);
        node.y(newY);
      }
  
      function downloadImage() {
        if (stageRef.value) {
          const originalWidth = stageSize.value.width;
          const originalHeight = stageSize.value.height;
  
          let targetWidth, targetHeight;
          if (originalWidth >= originalHeight) {
            targetWidth = 800;
            targetHeight = (originalHeight / originalWidth) * 800;
          } else {
            targetHeight = 800;
            targetWidth = (originalWidth / originalHeight) * 800;
          }
  
          const stage = stageRef.value.getStage();
          stage.width(targetWidth);
          stage.height(targetHeight);
          stage.scale({ x: targetWidth / originalWidth, y: targetHeight / originalHeight });
  
          const uri = stage.toDataURL();
          const link = document.createElement('a');
          link.download = 'composite-image.png';
          link.href = uri;
          link.click();
  
          stage.width(originalWidth);
          stage.height(originalHeight);
          stage.scale({ x: 1, y: 1 });
        }
      }
  
      const handleResize = () => {
        adjustStageSize();
      };
  
      onMounted(() => {
        window.addEventListener('resize', handleResize);
      });
  
      onBeforeUnmount(() => {
        window.removeEventListener('resize', handleResize);
      });
  
      // Watch to update the class when the main image is loaded
    // watch(
    //     () => mainImage.value?.src,
    //     (newSrc) => {
    //         isMainImageLoaded.value = !!newSrc;
    //     }
    // );
    watch(mainImage, (newImage) => {
        if (newImage) {
            adjustStageSize();
        }
    });

      return {
        loadMainImage,
        loadWatermark,
        mainImage,
        stageSize,
        downloadImage,
        stageRef,
        watermarkSettings,
        updateOpacity,
        watermarkProps,
        updateSize,
        handleDrag,
        containerRef,
        isMainImageLoaded,
      };
    },
  };
  </script>
  
  <style lang="sass" scoped>
  .image-editor
    display: flex
    flex-direction: column
    align-items: center
    width: 95%
    max-width: 1200px
    margin: 0 auto
  
  .editor-layout
    display: flex
    flex-direction: column
    width: 100%
    align-items: center
  
  .image-container
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    overflow: hidden
    box-sizing: border-box
    border-radius: 50px
    background: #e0e0e0
    box-shadow:  20px 20px 60px #bebebe, -20px -20px 60px #ffffff
  
  .control-panel
    display: flex
    flex-direction: column
    gap: 15px
    width: 95%
    margin: 0 auto
  
  .custom-file-upload
    display: inline-block
    padding: 10px 20px
    cursor: pointer
    border-radius: 25px
    background-color: #a0a0a0
    color: white
    font-weight: bold
    text-align: center
    font-size: 0.8em
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
    transition: background-color 0.3s ease, transform 0.2s ease
  
    input[type="file"]
      display: none
  
    &:hover
      background-color: #b0b0b0
      transform: translateY(-2px)
  
    &.loaded
      background-color: #a779ff
      &:hover
        background-color: #7e3bff
  
  input[type="range"]
    -webkit-appearance: none
    width: 100%
    height: 18px
    border-radius: 15px
    background: #ddd
    outline: none
    opacity: 0.8
    transition: opacity 0.2s
    cursor: pointer
    margin: 0 0 10px 0
    &::-webkit-slider-thumb
      -webkit-appearance: none
      appearance: none
      width: 40px
      height: 40px
      border-radius: 50%
      background: #6a0dad
      cursor: pointer
      transition: background-color 0.2s ease
    &::-moz-range-thumb
      width: 40px
      height: 40px
      border-radius: 50%
      background: #6a0dad
      cursor: pointer
      transition: background-color 0.2s ease
    &:hover
      opacity: 1
  
  .custom-button
    padding: 15px 20px
    margin-top: 30px
    background-color: #6a0dad
    color: white
    font-size: 16px
    font-weight: bold
    border: none
    border-radius: 25px
    cursor: pointer
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
    transition: background-color 0.3s ease, transform 0.2s ease
    &:hover
      background-color: #7a1edb
      transform: translateY(-2px)
  
  @media (min-width: 768px)
    .editor-layout
      flex-direction: row
      justify-content: space-between
  
      .image-container
        width: 60%
        margin-right: 20px
        max-width: 800px
        aspect-ratio: 1 / 1
        div
          width: 100%
          display: flex
          justify-content: center
          align-items: center
          .konvajs-content
            width: 100%
            canvas
              width: 100%
  
      .control-panel
        width: 40%
  </style>
  