import { createRouter, createWebHistory } from 'vue-router';
// import Home from '../views/HomeView.vue';
import ImageEditor from '../views/ImageEditor.vue';  // Asegúrate de importar tu componente de editor

const routes = [
  {
    path: '/',
    name: 'ImageEditor',
    component: ImageEditor
  }

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
