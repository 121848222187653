import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueKonva from 'vue-konva';

const app = createApp(App);


app.use(store)
app.use(router)
app.use(VueKonva)
app.mount('#app')
